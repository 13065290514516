export const useLegalArea = async (deliveryKey: string) => {
  const { getCanonicalUrl } = useRouteHelper()
  const { selectedIndex } = useSliderTransition()
  const router = useRouter().currentRoute

  const { data } = await useLegalAreaContainer(deliveryKey)

  const filteredContent = computed(
    () =>
      data.value?.content?.contents
        ?.filter(item => {
          const showInAllCountries = item.showInAllCountries
          const showInCountry = item.showInCountry
          // If both are undefined, return true
          if (showInAllCountries === undefined && showInCountry === undefined) {
            return true
          }
          // If showInAllCountries is false, check showInCountry
          if (showInAllCountries === false) {
            return showInCountry !== false
          }
          // Otherwise, return true
          return true
        })
        .filter(item => item.tabs?.url?.target?.target) ?? []
  )

  const tabs = computed(() => filteredContent.value.map(el => el.tabs))

  const selectedTabIndex = computed(() =>
    tabs.value?.findIndex(el =>
      getCanonicalUrl(router?.value.path).endsWith(
        getCanonicalUrl(el!.url!.target!.target!)
      )
    )
  )

  const currentPage = computed(() => {
    const rPath = getCanonicalUrl(router?.value.path)
    return (
      filteredContent.value?.find(el => {
        return rPath.endsWith(getCanonicalUrl(el!.tabs!.url!.target!.target!))
      }) ??
      filteredContent.value?.[0] ??
      null
    )
  })

  const pageContent = computed(() =>
    currentPage.value
      ? {
          content: {
            text: currentPage.value?.tabs?.name,
            links: currentPage.value?.links,
            body: sanitizeBody(currentPage.value?.body),
            giantShortcut: currentPage.value?.giantShortcut || [],
          },
        }
      : null
  )

  const setSelectedIndexFromRoute = () => {
    const currentPath = getCanonicalUrl(router.value.path)
    const index = tabs.value?.findIndex(
      el =>
        el?.url?.target?.target &&
        currentPath.endsWith(getCanonicalUrl(el?.url?.target?.target))
    )
    if (index !== undefined && index !== -1) {
      selectedIndex.value = index
    }
  }

  return {
    tabs,
    pageContent,
    selectedTabIndex,
    setSelectedIndexFromRoute,
  }
}
export type UseLegalArea = typeof useLegalArea

// Sanitize body. Remove text inside text property and remove meta
const sanitizeBody = (body: any) => {
  if (!body) return []
  const bodyCopy = JSON.parse(JSON.stringify(body))
  bodyCopy.forEach((el: any) => {
    if (el.text && typeof el.text === 'object') {
      el.text = el.text.text || ''
    }
    if (el._meta) {
      delete el._meta
    }
  })

  return bodyCopy
}
