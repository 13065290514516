export const useLegalAreaContainer = async (deliveryKey: string) => {
  const localePath = useLocalePath()

  const query = {
    locale: localePath(''),
    vse: useRoute().query.vse,
    contentId: useRoute().query.contentId,
    deliveryKey: deliveryKey,
  }

  const data = await useFetch('/api/cms/getLegalAreaContainer', {
    key: 'getLegalAreaContainer' + deliveryKey,
    dedupe: 'defer',
    query,
    getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
  })

  return data
}
